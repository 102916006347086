// frontend/src/NotFound.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="not-found-container">
      <h1>{t('404.title')}</h1>
      <p>{t('404.description')}</p>
      <Link to="/">{t('404.back_to_home')}</Link>
    </div>
  );
};

export default NotFound;